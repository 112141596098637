import React, { Suspense } from "react";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import {page_routes} from "./helper/page_routes";
import {ToastContainer} from "react-toastify";
import UserProvider from "./context/userProvider";
import PersistUserLoginLayout from "./layouts/persistUserLoginLayout";
import AxiosProvider from "./context/axiosProvider";
import PageLoader from "./components/pageLoader";
import Moment from 'react-moment';
import moment from 'moment-timezone';
const ProtectedRoutesLayout = React.lazy(()=>import("./layouts/protectedRoutesLayout"));
const UnprotectedRoutesLayout = React.lazy(()=>import("./layouts/unprotectedRoutesLayout"));
const DashboardLayout = React.lazy(()=>import("./layouts/dashboardLayout"));
const Dashboard = React.lazy(()=>import("./pages/dashboard"));
const Challenges = React.lazy(()=>import("./pages/challenges"));
const Recognition = React.lazy(()=>import("./pages/recognition"));
const Awards = React.lazy(()=>import("./pages/awards"));
const Rewards = React.lazy(()=>import("./pages/rewards"));
const Redemptions = React.lazy(()=>import("./pages/redemptions"));
const Profile = React.lazy(()=>import("./pages/profile"));
const MyDetail = React.lazy(()=>import("./pages/myDetail"));
const ProtectedAdminRoutesLayout = React.lazy(()=>import("./layouts/protectedAdminRoutesLayout"));
const AdminUsers = React.lazy(()=>import("./pages/admin/users"));
const AdminSkills = React.lazy(()=>import("./pages/admin/skills"));
const AdminEvents = React.lazy(()=>import("./pages/admin/events"));
const AdminVendors = React.lazy(()=>import("./pages/admin/vendors"));
const PageNotFound = React.lazy(()=>import("./pages/pageNotFound"));
const InsufficientPermissionPage = React.lazy(()=>import("./pages/insufficientPermissionPage"));
// const Login = React.lazy(()=>import("./pages/login"));

Moment.globalMoment = moment;
Moment.globalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
Moment.globalLocale = Intl.DateTimeFormat().resolvedOptions().locale;
Moment.globalLocal = true;

/*
 * App Component Containing Providers, Routes & Toast Container
 */

function App() {
  return (
    <>
      <UserProvider>
        <BrowserRouter basename={page_routes.dashboard}>
          <AxiosProvider>
            <Routes>
              <Route element={<PersistUserLoginLayout />}>
                <Route element={<ProtectedRoutesLayout />}>
                  <Route path={page_routes.dashboard} element={<DashboardLayout />}>
                    <Route path={page_routes.dashboard} element={<Dashboard />} /> {/* 👈 Renders Dashboard Screen at / */}
                    <Route path={page_routes.challenges} element={<Challenges />} /> {/* 👈 Renders Challenges Screen at / */}
                    <Route path={page_routes.recognition} element={<Recognition />} /> {/* 👈 Renders Recognition Screen at / */}
                    <Route path={page_routes.rewards} element={<Rewards />} /> {/* 👈 Renders Rewards Screen at / */}
                    <Route path={page_routes.redemtions} element={<Redemptions />} /> {/* 👈 Renders Redemptions Screen at / */}
                    <Route path={page_routes.awards} element={<Awards />} /> {/* 👈 Renders Rewards Screen at / */}
                    <Route path={page_routes.profile} element={<Profile />} /> {/* 👈 Renders Challenges Screen at / */}
                    <Route path={page_routes.myDetail} element={<MyDetail />} /> {/* 👈 Renders Challenges Screen at / */}
                    <Route path={page_routes.dashboard} element={<ProtectedAdminRoutesLayout />}>
                      <Route path={page_routes.admin} element={<AdminUsers />} />
                      <Route path={page_routes.admin_skills} element={<AdminSkills />} />
                      <Route path={page_routes.admin_events} element={<AdminEvents />} />
                      <Route path={page_routes.admin_vendors} element={<AdminVendors />} />
                    </Route>
                  </Route>
                </Route>

                <Route element={<UnprotectedRoutesLayout />}>
                  {/* <Route path={page_routes.login} element={<Login />} /> 👈 Renders Login Screen at /login */}
                  <Route path={page_routes.login} Component={() => {
                    window.location.href = process.env.REACT_APP_CURRENT_ENV==="production" ? "https://portal.maximumaccountability.net/login?returnURL=https://ee.maximumaccountability.net" : "https://portal-staging.maximumaccountability.net/login?returnURL=https://ee-staging.maximumaccountability.net";
                    return null;
                  }} /> 👈 Renders Login Screen at /login
                </Route>
              </Route>
              <Route element={<Suspense fallback={<PageLoader />}>
                  <Outlet />  
                </Suspense>}>
                <Route path={page_routes.insufficientPermission} element={<InsufficientPermissionPage />} /> {/* 👈 Renders Page Not Found Screen */}
                <Route path="*" element={<PageNotFound />} /> {/* 👈 Renders Page Not Found Screen */}
              </Route>
            </Routes>
          </AxiosProvider>
        </BrowserRouter>
      </UserProvider>
      <ToastContainer />
    </>
  );
}

export default App;
