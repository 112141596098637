import React, { createContext, useContext, useEffect } from "react";
import { ChildrenType } from "../helper/types";
import axiosPublic from "../helper/axios";
import { AxiosInstance } from "axios";
import { useUser } from "./userProvider";
import { useNavigate } from "react-router-dom";
import { page_routes } from "../helper/page_routes";

/*
  * Axios Context Type
*/
type AxiosContextType = {
    axios: AxiosInstance;
}

/*
  * Axios Context Default Value
*/
const axiosDefaultValues: AxiosContextType = {
  axios: axiosPublic
};

/*
  * Axios Context
*/
const AxiosContext = createContext<AxiosContextType>(axiosDefaultValues);

export const useAxios = () => useContext(AxiosContext);

/*
  * Axios Provider
*/
const AxiosProvider: React.FC<ChildrenType> = ({ children }) => {
  const {user, isAuthenticated, removeUser} = useUser();
  const navigate = useNavigate();
  

  useEffect(() => {
    const requestInterceptor = axiosPublic.interceptors.request.use(
      config => {
          if(!config.headers['authorization'] && user!==null){
            config.headers['authorization'] = `Bearer ${user.token}`;
          }
          return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosPublic.interceptors.response.use(
        response => response,
        async (error) => {
          if(error?.response?.status === 401){
            removeUser();
          }
          if(error?.response?.status === 403){
            navigate(page_routes.insufficientPermission, {replace: true});
          }
          return Promise.reject(error);
        }
    );

    return () => {
        axiosPublic.interceptors.request.eject(requestInterceptor);
        axiosPublic.interceptors.response.eject(responseInterceptor);
    }
  }, [isAuthenticated, removeUser, user])

  return (
    <AxiosContext.Provider
      value={{ axios: axiosPublic }}
    >
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosProvider;