import { FC } from "react"
import Spinner from 'react-bootstrap/Spinner';
import AuthLayout from "../../layouts/authLayout";

/*
  * Initial Page Loader Component
*/
const PageLoader:FC = () => {
  return (
    <AuthLayout>
        <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    </AuthLayout>
  )
}

export default PageLoader