import { FC } from "react"
import { ChildrenType } from "../../helper/types"
import { Link } from "react-router-dom"
import { page_routes } from "../../helper/page_routes"
import logo from '../../assets/images/logo.webp'
import authCss from './auth.module.css'

/*
  * Auth Screen Layout containing outlet
*/
const AuthLayout:FC<ChildrenType> = ({children}) => {
    
    return (
        <div className={authCss.auth_container}>
            <div className="container">
                <div className="row justify-content-center align-items-center authentication h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                        <div className="mb-3 d-flex justify-content-center">
                            <Link to={page_routes.login}>
                                <img src={logo} alt="logo" className={authCss.authentication_desktop_logo} />
                            </Link>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout