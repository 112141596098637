const env = require("./env."+process.env.REACT_APP_CURRENT_ENV+".json")

type ENV_TYPE = {
    API_ENDPOINT: string;
    MAIN_URL: string;
    webSocket_URl:string;
}

/*
  * Env declaration taken from the json files based on the app environment type
*/
export const ENV:ENV_TYPE = env