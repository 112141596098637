import { FC, Suspense, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { useUser } from "../context/userProvider";
import PageLoader from "../components/pageLoader";
import { api_routes } from "../helper/api_routes";
import { useAxios } from "../context/axiosProvider";
import { UserType } from "../helper/types";

/*
  * Layout that checks auth token is valid or not. if valid then persist user data or token else invalidate existing auth token
*/
const PersistUserLoginLayout:FC = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const { setUser, setParentUser, setParentUserOrg, removeUser, userCookieData, parentUserCookieData, parentUserOrgCookieData } = useUser();
    const { axios } = useAxios();

    
    useEffect(() => {
        
        let isMounted = true;
        const checkUserAuthenticated = async () => {
            // if(userCookieData && parentUserCookieData && parentUserOrgCookieData && isMounted){
            //     try {
            //         await axios.post(api_routes.profile.checkLogin, {});
            //         setUser(userCookieData)
            //         setParentUser(parentUserCookieData)
            //         setParentUserOrg(parentUserOrgCookieData)
            //     } catch (error) {
            //         removeUser();
            //     }
            // }
            // else{
            //     removeUser();
            // }
            // isMounted && setLoading(false)

            try {
                const response = await axios.post<{data:{finalData:UserType}}>(api_routes.profile.checkLogin, {});
                if(userCookieData && isMounted){
                    setUser(userCookieData)
                    if(parentUserCookieData && isMounted){
                        setParentUser(parentUserCookieData)
                    }
                    if(parentUserOrgCookieData && isMounted){
                        setParentUserOrg(parentUserOrgCookieData)
                    }
                }else{
                    setUser(response.data.data.finalData)
                    setParentUser(response.data.data.finalData)
                    setParentUserOrg({
                        oId:response.data.data.finalData.user.profile.oid,
                        oname:response.data.data.finalData.user.profile.oname
                    })
                }
            } catch (error) {
                removeUser();
            }finally{
                isMounted && setLoading(false)
            }
        }
        
        // (userCookieData && parentUserCookieData && parentUserOrgCookieData && isMounted) ? checkUserAuthenticated() : setLoading(false)
        (isMounted) ? checkUserAuthenticated() : setLoading(false)

        return () => {isMounted = false; checkUserAuthenticated()};

    }, [axios, parentUserCookieData, parentUserOrgCookieData, removeUser, setParentUser, setParentUserOrg, setUser, userCookieData])
    
    return (
        <>
            {
                loading ? <PageLoader /> : <Suspense fallback={<PageLoader />}>
                    <Outlet />
                </Suspense>
            }
        </>
    )
}

export default PersistUserLoginLayout