/*
 * Page routes list
 */

export const page_routes = {
  dashboard: "/",
  challenges: "/challenges",
  myDetail: "/my-detail",
  profile: "/profile",
  login: "/login",
  admin: "/admin/users",
  admin_skills: "/admin/skills",
  admin_events: "/admin/events",
  admin_vendors: "/admin/vendors",
  recognition: "/recognition",
  rewards: "/rewards",
  redemtions: "/redemtions",
  awards: "/awards",
  insufficientPermission: "/403",
};
